<template>
  <div class="container margin">
    <div class="credit">
      <side-nav>
        <div class="credit__content">
          <div class="credit__info flex flex__align-center">
            <div class="credit__info-item flex flex__align-center credit__info-item_mr155">
              <img :src="getFileAccessHttpUrl(avatar)" alt class="credit__info-img" />
              <div class="credit__info-name">{{userName}}</div>
            </div>
            <div class="credit__info-item credit__info-item_mr202">
              <div class="credit__info-num">{{userData.rank}}</div>
              <div class="credit__info-text">我的排名</div>
            </div>
            <div class="credit__info-item">
              <div class="credit__info-num credit__info-num_left">{{userData.score}}</div>
              <div class="credit__info-text credit__info-text_click" @click="toDetails">
                我的学分
                <a-icon type="right" />
              </div>
            </div>
            <div class="credit__info-line" style="left:290px" />
            <div class="credit__info-line" style="left:540px" />
          </div>
          <div class="credit__list">
            <!-- 筛选表单 -->
            <div class="credit__filter">
              <div class="flex flex__space-between" style="padding-bottom:20px">
                <div class="credit__filter-item flex flex__align-center">
                  <div class="credit__filter-text">姓名:</div>
                  <a-input v-model="queryParam.userRealname" style="width:280px" placeholder="请输入" />
                </div>
                <div class="credit__filter-item flex flex__align-center">
                  <div class="credit__filter-text">部门:</div>
                  <a-tree-select
                    style="width:280px"
                    :treeData="departTree"
                    v-model="queryParam.sysOrgId"
                    placeholder="请选择部门"
                  ></a-tree-select>
                </div>
              </div>
              <div class="flex flex__space-between">
                <div class="credit__filter-item flex flex__align-center">
                  <div class="credit__filter-text">岗位:</div>
                  <position-select style="width:280px" v-model="queryParam.positionName"></position-select>
                </div>
                <div class="center" id="center">
                  <a-button
                    id="btn"
                    type="primary"
                    shape="round"
                    style="width:94px; margin-right:90px;"
                    @click="submit"
                  >查询</a-button>
                 <a-button shape="round" style="width:94px;  background: rgb(177, 177, 177);" html-type="submit"
                    @click="reset">重置</a-button>
                </div>
              </div>
            </div>
            <!-- 表格 -->
            <div class="credit__table">
              <a-table
                :columns="columns"
                :rowKey="(record) => record.userId"
                :loading="loading"
                :data-source="data"
                :pagination="pagination"
                @change="paginationChange"
              >
                <template slot="rank" slot-scope="text">
                  <img
                    v-if="text === 1"
                    src="@/assets/images/home/top1.png"
                    class="credit__table-img"
                  />
                  <img
                    v-else-if="text === 2"
                    src="@/assets/images/home/top2.png"
                    class="credit__table-img"
                  />
                  <img
                    v-else-if="text === 3"
                    src="@/assets/images/home/top3.png"
                    class="credit__table-img"
                  />
                  <span v-else>{{text}}</span>
                </template>
                <!-- <span slot="rank" slot-scope="record">
                  <img
                    v-if="record.rank === 1"
                    src="@/assets/images/home/top1.png"
                    class="credit__table-img"
                  />
                  <img
                    v-else-if="record.rank === 2"
                    src="@/assets/images/home/top2.png"
                    class="credit__table-img"
                  />
                  <img
                    v-else-if="record.rank === 3"
                    src="@/assets/images/home/top3.png"
                    class="credit__table-img"
                  />
                  <span v-else>{{record.rank}}</span>
                </span>-->
              </a-table>
            </div>
          </div>
        </div>
      </side-nav>
    </div>
  </div>
</template>
 
<script  event="onkeydown">
import { getUserData } from "@/api/user";
import sideNav from "@/components/side-nav";
import { getScoreList } from "@/api";
import positionSelect from "@/components/positionSelect/index.vue";
import { queryTreeList } from "@/api/home.js";
import {
  USER_NAME,
  SCORE,
  USER_AVATAR,
  USER_ID
} from "@/store/mutation-types.js";
import { getFileAccessHttpUrl } from "@/utils/tools";
import Vue from "vue";
const columns = [
  {
    title: "排名",
    dataIndex: "rank",
    key: "rank",
    align: "center",
    scopedSlots: { customRender: "rank" }
  },
  {
    title: "姓名",
    dataIndex: "userRealname",
    key: "userRealname",
    align: "center"
  },
  {
    title: "部门",
    dataIndex: "sysOrgName",
    key: "sysOrgName",
    align: "center"
  },
  {
    title: "岗位",
    dataIndex: "positionName",
    key: "positionName",
    align: "center"
  },
  {
    title: "学分",
    dataIndex: "score",
    key: "score",
    align: "center"
  }
];

export default {
  components: { sideNav, positionSelect },
  data() {
    return {
      data: [],
      loading: false,
      columns,
      departTree: [],
      rank: [],
      userData: {},
      positionData: [],
      score: Vue.ls.get(SCORE),
      userName: Vue.ls.get(USER_NAME),
      avatar: Vue.ls.get(USER_AVATAR),
      userId: Vue.ls.get(USER_ID),
      queryParam: {},
      formInline: {
        name: "",
        department: ""
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.getData();
    this.getScore();
    this.getDeptList();
    this.getRankname();
    // this.getPositionList();
  },
  computed: {
    getFileAccessHttpUrl() {
      return avatar => {
        return getFileAccessHttpUrl(avatar);
      };
    }
    // 计算排名
    // getRank() {
    //   var value = "";
    //   this.rank.forEach((item, index) => {
    //     if (item.userId === this.userId) {
    //       return (value = index + 1);
    //     }
    //   });
    //   return value;
    // }
  },
  methods: {
    getData() {
      this.loading = true;
      const paramter = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      Object.assign(paramter, this.queryParam);
      getScoreList(paramter)
        .then(res => {
          this.data = res.result.records;
          this.pagination.total = res.result.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRankname() {
      this.loading = true;
      const paramter = {
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      Object.assign(paramter, this.queryParam);
      getScoreList(paramter)
        .then(res => {
          this.rank = res.result.records;
          this.pagination.total = res.result.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getScore() {
      getUserData().then(res => {
        this.userData = res.result;
      });
    },
    getDeptList() {
      queryTreeList().then(res => {
        this.departTree = res.result;
      });
    },
    submit() {
      this.pagination.current = 1;
      this.getData();
    },
    paginationChange(page) {
      this.pagination.current = page.current;
      this.pagination.pageSize = page.pageSize;
      this.getData();
    },
    toDetails() {
      this.$router.push({
        name: "creditDetail"
      });
    },
    reset() {
      this.queryParam = {};
    }
  }
};
document.onkeydown = function(event) {
  var e = event || window.event || arguments.callee.caller.arguments[0];
  if (e && e.keyCode == 13) {
    document.getElementById("btn").click();
  }
};
</script>
 
<style lang = "scss" scoped>
.credit {
  &__content {
    height: 1183px;
  }

  &__info {
    /* width: 100%; */
    /* height: 146px; */
    /* background: url("~@/assets/images/home/credit-bg.png") no-repeat; */
    /* background-size: 100% 100%; */
    box-sizing: border-box;
    padding: 0 56px;
    position: relative;
    width: 825px;
    height: 146px;
    background: #ffffff;
    border: 6px solid rgba(200, 200, 200, 0.17);
    border-radius: 8px;
    transform: translateX(33px);

    &-item {
      text-align: center;

      &_mr155 {
        padding-right: 80px;
        margin-right: 60px;
        padding-left: 84px;
        margin-left: 60px;
      }
      &_mr202 {
        margin-right: 202px;
      }
    }
    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 16px;
    }
    &-name {
      font-size: 30px;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #000000;
    }
    &-num {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      &_left {
        text-align: left;
      }
    }
    &-text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      transition: all 0.6s;

      &_click {
        cursor: pointer;
        &:hover {
          color: #376cde;
        }
      }
    }
    &-line {
      width: 1px;
      height: 68px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
    }
  }
  &__list {
  }
  &__filter {
    padding: 30px 30px;
    &-text {
      margin-right: 10px;
    }
  }
  &__table {
    width: 824px;
    transform: translateX(33px);

    &-img {
      width: 26px;
      color: #FF3434;
    }
  }
}

::v-deep .ant-btn {
  width: 94px;
  height: 38px;
  border-radius: 25px;
  color: #ffffff;
  background: #D6A97E;
}
.center {
  margin-left: -180px;
}
.border1 {
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
}
.table-striped {
  color: #FF3434;
}
/* .ant-table-striped :deep(.table-striped) td {
  color: #FF3434;
} */

::v-deep .ant-table-thead>tr>th {
  height: 41px;
  background: #F5F5F5;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #AAAAAA;
}

.color {
  font-size: 14px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #FF3434;
}
</style>