<template>
  <a-select
    showSearch
    class="width-100"
    :value="selectValue"
    :allowClear="allowClear"
    :disabled="disabled"
    :placeholder="placeholder"
    :mode="mode"
    @change="handleChange"
    optionFilterProp="children"
  >
    <a-select-option v-for="(item) in postList" :key="item.name" :value="item.id">{{ item.name }}</a-select-option>
  </a-select>
</template>

<script>
import { positionList } from "@/api/home.js";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    allowClear: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default: "请选择",
      required: false
    },
    mode: {
      type: String,
      default: "default",
      required: false
    }
  },
  data() {
    return {
      postList: [],
      selectValue: undefined
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  created() {
    this.getSelectList();
  },
  watch: {
    value: {
      immediate: true,
      handler: function() {
        this.initVal();
      }
    }
  },
  // computed: {
  //   filteredOptions() {
  //     return this.postList.filter(o => !this.selectValue.includes(o))
  //   }
  // },
  methods: {
    // 获取岗位列表
    getSelectList() {
      var param = {};
      positionList(param).then(res => {
        if (res.success) {
          this.postList = res.result;
        }
      });
    },
    initVal() {
      if (!this.value) {
        this.selectValue = undefined;
      } else {
        let text = this.value;
        if (this.mode === "default") {
          text = this.value;
        } else {
          text = this.value.split(",");
        }
        this.selectValue = text;
      }
    },
    // 切换选中项
    handleChange(value) {
      var text = "";
      if (this.mode === "default") {
        text = value;
      } else {
        text = value.join();
      }
      this.selectValue = value;
      this.$emit("change", text);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
